import React from "react";
import { useInView } from "react-intersection-observer";
import "./Team.css"; // Import styling
import { Abinaya, Kaviya, Poornisha, Pooja, Prithivi, Risika, Sudharsan, Purusothaman } from "../../assets";
import { FaLinkedin, FaEnvelope, FaGithub } from "react-icons/fa";

const teamMembers = [
  { name: "Kaviya S", role: "Developer & Project Organizer", img: Kaviya, linkedin: "#", github: "#", gmail: "kaviya@y-knot.in" },
  { name: "Poornisha S", role: "Backend Developer", img: Poornisha, linkedin: "#", github: "#", gmail: "poornisha261@gmail.com" },
  { name: "Abinaya M", role: "Full Stack & Mobile App Developer", img: Abinaya, linkedin: "#", github: "#", gmail: "abinaya.m2625@gmail.com" },
  { name: "Prithivi P", role: "Designing & Frontend Developer", img: Prithivi, linkedin: "#", github: "#", gmail: "prithiviindirani@gmail.com" },
  { name: "Pooja R", role: "Designing & Frontend Developer", img: Pooja, linkedin: "#", github: "#", gmail: "kaviyapooja8450@gmail.com" },
  { name: "Risika K", role: "Backend Developer", img: Risika, linkedin: "#", github: "#", gmail: "risikakandasamymalar@gmail.com" },
  { name: "Purusothaman T", role: "Mobile App Developer", img: Purusothaman, linkedin: "#", github: "#", gmail: "purusoththirupathi20@gmail.com" },
  { name: "Sudharsan E", role: "Fullstack Developer", img: Sudharsan, linkedin: "#", github: "#", gmail: "sudhansudharsan964@gmail.com" },
];

/* Team Member Component with Fade Effect */
const TeamMemberCard = ({ member }) => {
  const { ref, inView } = useInView({ triggerOnce: true, threshold: 0.2 });

  return (
    <div ref={ref} className={`team-card fade-section ${inView ? "visible" : ""}`}>
      <div className="card-content">
        <img src={member.img} alt={member.name} className="profile-img" />
        <h3>{member.name}</h3>
        <small>{member.role}</small>
        <div className="social-icons">
          <a href={member.linkedin} target="_blank" rel="noopener noreferrer"><FaLinkedin /></a>
          <a href={member.github} target="_blank" rel="noopener noreferrer"><FaGithub /></a>
          <a href={`mailto:${member.gmail}`} target="_blank" rel="noopener noreferrer"><FaEnvelope /></a>
        </div>
      </div>
    </div>
  );
};

const Team = () => {
  return (
    <section className="team">
      <h2>Meet Our Team</h2>
      <hr/>
      <div className="team-container">
        {teamMembers.map((member, index) => (
          <TeamMemberCard key={index} member={member} />
        ))}
      </div>
    </section>
  );
};

export default Team;

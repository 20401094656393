import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Hero.css";
import "./button.css";

const Hero = () => {
  const navigate = useNavigate();
  const text = "Why wait? Why not you? Why not now?";
  const [displayedText, setDisplayedText] = useState("");
  const [index, setIndex] = useState(0);
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    if (index < text.length) {
      const interval = setTimeout(() => {
        setDisplayedText(text.slice(0, index + 1));
        setIndex(index + 1);
      }, 100);
      return () => clearTimeout(interval);
    }
  }, [index, text]);

  useEffect(() => {
    const heroSection = document.querySelector(".hero-section");
    if ("IntersectionObserver" in window) {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              setIsVisible(true);
              observer.unobserve(entry.target);
            }
          });
        },
        { threshold: 0.1 }
      );
      observer.observe(heroSection);
    } else {
      setIsVisible(true);
    }
  }, []);

  return (
    <div className={`hero-section ${isVisible ? "lazy-bg" : ""}`}>
      <div className="overlay"></div>
      <div className="hero-content text-center">
        <br />
        <br />
        <h1>
          At Y-knot, we turn ideas into reality. Our team is here to bring your
          vision to life. Let’s build something extraordinary together!
        </h1>
        <br />
        <p className="typewriter">{displayedText}</p>
        <br />
        <div className="button-wrapper">
          <button
            className="gradient-border-button"
            onClick={() => navigate("/pages/About/About.jsx")}
          >
            <span>Explore Now</span>
          </button>
        </div>
      </div>
    </div>
  );
};

export default Hero;

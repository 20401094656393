import { useState, useEffect } from "react";
import "./contact.css";
import { motion } from "framer-motion";
const ContactForm = () => {
  
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    number: "",
    message: "",
  });

  const [status, setStatus] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // Allow only numbers for the phone number field
    if (name === "number" && !/^\d*$/.test(value)) {
      return;
    }

    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const response = await fetch("https://api.web3forms.com/submit", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ ...formData, access_key: "6f70bdc1-913c-4d18-9fa4-918ae45c1de6" }),
    });

    const result = await response.json();
    setIsLoading(false);
    setStatus(result.success ? "" : "Submission Failed. Try again.");

    if (result.success) {
      setFormData({ name: "", email: "", number: "", message: "" });
      setTimeout(() => {
        alert("Message Sent Successfully! Get in touch with you soon.");
      }, 500);
    }
  };

  return (
    <div className="contactbg">
      <div className="contact-container">
        <form onSubmit={handleSubmit} className="contact-left">
          <div className="contact-left-title">
            <h2>Contact Us</h2>
            <hr />
          </div>
          <input 
            type="text" 
            name="name" 
            placeholder="Enter Your Name" 
            value={formData.name} 
            onChange={handleChange} 
            required 
            className="contact-inputs" 
          />
          <input 
            type="email" 
            name="email" 
            placeholder="Enter Your Email" 
            value={formData.email} 
            onChange={handleChange} 
            required 
            className="contact-inputs" 
          />
          <input 
            type="tel" 
            name="number" 
            placeholder="Enter Your Phone Number" 
            value={formData.number} 
            onChange={handleChange} 
            required 
            className="contact-inputs" 
          />
          <textarea 
            name="message" 
            placeholder="Enter Your Message" 
            value={formData.message} 
            onChange={handleChange} 
            required 
            className="contact-inputs" 
          ></textarea>
          <button 
            type="submit" 
            className="contact-left button" 
            disabled={isLoading}
          >
            {isLoading ? "Submitting..." : "Submit"}
          </button>
        </form>


        <div className="contact-right">
         <h1>To Reach us</h1>
        
       <div className="contact-details">
       <p><strong>Phone:</strong> <a href="tel:+1234567890">+91 72009 63755</a></p>
       <p><strong>Email:</strong> <a href="mailto:info@y-knot.in">info@y-knot.in</a></p>
         
      
        </div>
      </div>


        {status && <p className="mt-4 text-center text-grey-700">{status}</p>}
      </div>
    </div>
  );
};

export default ContactForm;

import React, { useEffect } from "react";
import "./ProjectPage.css";

const ProjectPage = () => {
  useEffect(() => {
    document.title = "Y-knot";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="project-page">
      <div className="header-image">
        <div className="header-text">
          <h1>Our Projects</h1>
          <p>
          At Y-Knot, we develop innovative solutions integrating automation, IoT, and smart technology.
          Our Lift and Learn using RFID enhances interactive retail experiences, while the Android app
          for solar power switching optimizes energy management.At Y-Knot, we build smart, scalable, and 
          future-ready solutions—so why not collaborate with us?
          </p>
        </div>
      </div>

      {/* Common heading centered */}
      <h1 className="common-heading"><center>Technology We Use</center></h1>

      <div className="projects-container">
        {projects.map((project, index) => (
          <div key={index} className="project-card">
            <h2>{project.title}</h2>
            <p>{project.description}</p>
            <a href="#">→</a>
          </div>
        ))}
      </div>
    </div>
  );
};

const projects = [
  { title: "AI and ML", description: "Specialized in AI and ML, developing intelligent solutions to automate processes, enhance decision-making, and drive business growth." },
  { title: "Blockchain", description: "We implement, process, and technology, to solve complex problems for our clients, including secure transactions and fraud-proof systems." },
  { title: "Fullstack", description: "Offering full-stack development services, building scalable and high-performance web and mobile applications with seamless front-end and back-end integration." },
  { title: "Embedded System Projects", description: "Integrate hardware and software for real-time applications, including automation projects." },
  { title: "IOT Projects", description: "Enable smart automation and remote monitoring by connecting devices to the internet." },
  { title: "ERP Solutions", description: "Helping businesses with advanced ERP frameworks for optimized operations." }
];

export default ProjectPage;

import React from "react";
import VisitorsCount from "./VisitorsCount"; 
import "./VisitorsPage.css"; 

const VisitorsPage = () => {
  return (
    <div className="visitors-page text-center">
  
      <VisitorsCount /> 
      
      <br/>
      <br/>
      <br/>
    </div>
  );
};

export default VisitorsPage;

import React from "react";
import './Footer.css';
import { FaEnvelope } from "react-icons/fa";

function Footer() {
  return (
    <footer className="bg-dark text-white text-center py-3" style={{ background: "transparent" }}>
      <div className="container">
        <p>&copy; {new Date().getFullYear()} Y-knot. All Rights Reserved.</p>
        
        <a href={`mailto:info@y-knot.in`} target="_blank" rel="noopener noreferrer"><FaEnvelope /> info@y-knot.in</a>

      </div>
    </footer>
  );
}

export default Footer;

import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa"; 
import "./VisitorsCount.css"; 

const VisitorsCount = () => {
  const [visitorCount, setVisitorCount] = useState(0);

  useEffect(() => {
    let count = localStorage.getItem("visitorCount");
    if (!count) {
      count = 1;
    } else {
      count = parseInt(count) + 1;
    }
    localStorage.setItem("visitorCount", count);
    setVisitorCount(count);
  }, []);

  return (
    <div className="visitor-counter text-center">
      <h2>Visitors Count</h2>
      <div className="visitor-icon">
        <FaEye size={30} color="black" /> 
        <span className="count-text">{visitorCount}</span> 
      </div>
    </div>
  );
};

export default VisitorsCount;

import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Hero from "./Hero";
import Navbar from "./Navbar";
import Footer from "./Footer";
import VisitorsPage from "./VisitorsPage"; 
import "./App.css";
import About from "./pages/About/About";
import ContactForm from "./pages/contact";
import Services from "./pages/Service/Service";
import ProjectPage from "./pages/ProjectPage";
function App() {
  return (
    <Router>
      <div className="app-container">
        <Navbar />
        <Routes>
          <Route path="/" element={<Hero />} />
          <Route path="/visitors" element={<VisitorsPage />} /> 
          <Route path="/pages/About/About.jsx" element={<About />} />
          <Route path="/pages/contact.jsx" element={<ContactForm />} />
          <Route path="/pages/Service/Service.jsx" element={<Services/>} />
          <Route path="/pages/ProjectPage.jsx" element={<ProjectPage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}


export default App;

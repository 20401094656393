import { useEffect } from "react";
import { motion } from "framer-motion";
import { FaCode, FaMobileAlt, FaAward, FaBlenderPhone } from "react-icons/fa";
import "./Service.css";

const services = [
  {
    title: "Web Development",
    description:
      "Your website is your brand's digital face. Make it stand out! Collaborate with us to develop a simple, dynamic, or complex web application to enhance your business needs.",
    icon: <FaCode />,
  },
  {
    title: "App Development",
    description:
      "Custom mobile apps developed by our team to enhance user interaction with your business. Start now and share your vision through an app.",
    icon: <FaMobileAlt />,
  },
  {
    title: "Final Year Projects",
    description:
      "Unique and innovative final year projects to establish your career identity. Our team delivers solutions for your future project ideas. Why not join us now?",
    icon: <FaAward />,
  },
  {
    title: "Real Time Project",
    description:
      "Stunning ideas and marketing materials to make your brand stand out. Our team helps bring your real-time project to life.",
    icon: <FaBlenderPhone />,
  },
];

export default function Services() {
  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top when the page loads
  }, []);

  return (
    <div className="services-container">
      <h2 className="services-title">Our Services</h2>
      <p className="services-paragraph">
        We strive to provide the best solutions tailored to your business needs. Our team of experts ensures high-quality results with the latest technologies.
        Whether you're looking to build a new website, develop a mobile application, or enhance your projects, we've got you covered.
      </p>
      <div className="services-grid">
        {services.map((service, index) => (
          <motion.div
            key={index}
            className="service-card"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            whileHover={{
              scale: 1.05,
              boxShadow: "6px 6px 20px rgba(0, 255, 255, 0.4)",
            }}
            transition={{ duration: 0.2, delay: index * 0.1 }}
            style={{
              width: "250px",
              height: "300px",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
              textAlign: "center",
              padding: "1rem",
              background: "white",
              borderRadius: "10px",
            }}
          >
            <div
              className="service-icon"
              style={{ fontSize: "2.5rem", marginBottom: "1rem", color: "#007bff" }}
            >
              {service.icon}
            </div>
            <h3
              className="service-title"
              style={{ margin: "0.5rem 0", fontSize: "1.2rem", fontWeight: "bold" }}
            >
              {service.title}
            </h3>
            <p
              className="service-description"
              style={{ fontSize: "0.9rem", color: "#555", flexGrow: 1 }}
            >
              {service.description}
            </p>
          </motion.div>
        ))}
      </div>
    </div>
  );
}

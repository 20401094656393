import React, { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import Team from "../../components/Team/Team"; 
import "./About.css"; 

const About = () => {
  const { ref: aboutRef, inView: aboutInView } = useInView({ triggerOnce: true, threshold: 0.2 });

 
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about-container">
      {/* Hero Section */}
      <div className="about-image">
        <div className="about-text">
          <h1>About us</h1>
          <p className="about-main">
            Whether you're a student looking for a project, or an enterprise seeking innovation—we're here to make it happen!
            Our team is here to bridge the gap between ideas and execution, turning possibilities into success stories.
          </p>
        </div>
      </div>

      {/* About Us Section */}
      <section ref={aboutRef} className={`about fade-section ${aboutInView ? "visible" : ""}`}>
        <div className="features">
          <div className="feature-box">
            <h3>Our Motive</h3>
            <p>Join hands with Y-Knot, where every collaboration leads to innovation!</p>
            <p>At Y-Knot, we believe in limitless possibilities —so why not collaborate with us?
               Our name reflects our core philosophy: Why should you hesitate when you can tie up with us for success?</p>
          </div>
        </div>
      </section>

      {/* Team Section */}
      <section className="team-section">
        <Team />
      </section>
    </div>
  );
};

export default About;
